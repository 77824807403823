import * as React from 'react'
import Layout from '../../components/Layout'
import banner from '../../images/news/2years/banner.jpg'
import img_1_0 from '../../images/news/2years/img_1_0.jpg'
import img_1_1 from '../../images/news/2years/img_1_1.jpg'
import img_1_2 from '../../images/news/2years/img_1_2.jpg'
import img_2_0 from '../../images/news/2years/img_2_0.jpg'
import img_2_1 from '../../images/news/2years/img_2_1.jpg'
import img_2_2 from '../../images/news/2years/img_2_2.jpg'



const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "

export default function PostPage() {
    return (
        <Layout title='Celebrating Our 2nd Anniversary | Conex Research'>
            <section className='w-screen h-fit mt-6 mb-20'>

                <div className='px-8 lg:px-20'>

                    <h5 className='mb-10 sm:hidden'><a href='/media'>{'< '}Back to News</a></h5>
                    <h3 className='text-xl ws:text-3xl font-light text-slate-700 mb-3'>26 Apr 2022</h3>
                    <h1 className='text-5xl sm:text-7xl ws:text-8xl font-semibold mb-3'>Celebrating 2 Years of Collaboration</h1>
                    <h2 className='text-xl ws:text-3xl sm:text-2xl mb-3'>Looking over 2 years of growth back to our start</h2>

                    <div className='grid grid-rows-1 grid-cols-2'>

                        <h3 className='font-light text-slate-700 justify-self-start ws:text-2xl'>By <a className='text-conex-blue' href='https://twitter.com/jemckevitt' target='_blank'>James McKevitt</a></h3>

                        <div className='w-full flex flex-row justify-end gap-3'>

                            <a href={'https://twitter.com/intent/tweet?url=https%3A%2F%2Fconexresearch.com%2Fnews%2F2years&via=ConexResearch&text=Celebrating%20Our%202nd%20Anniversary'} title="Share on Twitter" target='_blank' className="flex items-center p-1">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5 text-conex-blue">
                                    <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"></path>
                                </svg>
                            </a>

                        </div>
                        
                    </div>

                </div>

                <div className='w-full h-fit mt-20'>
                    <img src={banner} className='h-full w-full'/>
                </div>

            </section>

            <section>

                <p className='font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64'>

                    <strong className='font-semibold'>Background</strong><br/><br/>

                    This month marks Conex Research’s two-year anniversary of international space research.<br/><br/>
                    
                    Our leadership team would like to extend our thanks to everyone who has contributed to the organisation’s research endeavours.
                    
                    The belief in what we are doing, and building bridges around the world is ever more important in these current times.<br/><br/>
                    
                    We value our team immensely, and we would not be close to where we are today without any one of them.

                </p>


                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>

                    <strong className='font-semibold'>Early Beginnings</strong><br/><br/>

                    
                    Loughborough University and the Space Society there is where a lot of our original members grounded their future careers as engineers and professionals in the space sector.<br/><br/>
                    
                    Looking for ways to continue meetings following the onset of COVID-19, a shift to online working seemed logical and meant that rather than collaborating on physical engineering projects we could collaborate in the hypothetical blue-sky space of mission proposals.
                    
                    We initially sought participants from beyond our old university in the UK through online events websites, and quickly began to organically grow as a team.<br/><br/>
                    
                    With more productive and fruitful discussions grew the need to formalise our group into Conceptual Exploration Research, or <strong>Conex</strong>.
                    
                    More structured sessions and goals, a dedicated website and communication channels were introduced, and a brand identity was created.<br/><br/>
                    
                    “Christina and I were planning our first ‘Online Space Mission Designathon’ two years ago, as we ended our roles on the Committee of Loughborough Space Society” said James McKevitt, Co-Executive Director.
                    
                    “The humble beginnings of a journey connecting strangers from around the world, united in their pursuit of advancing science for a better tomorrow, and taking us to St. Petersburg, Dubai, and London! And what a journey it has been.”

                </p>


                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                        <img src={img_1_0} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_1_1} className=' rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_1_2} className='rounded-xl'/>
                    </div>
                </div>


                <p className='font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64'>

                    <strong className='font-semibold'>The Conex Seal</strong><br/><br/>
                    
                    To communicate our values and our vision and in addition to the Conex logo, the Conex seal perfectly describes our team and is something we proudly attach to our work.<br/><br/>

                    The central blue sphere is our ‘blue marble’ Earth and the distributed connected nodes in white represent the global and interconnected members upon which everything at Conex is based.
                    
                    The gold spheres on either side clearly define the Earth by showing the remaining planets in the Solar System, and the surrounding stars serve as a reminder of the vast space within which we all reside.
                    
                    The crossing red streak reaches beyond the central region of the seal, representing our team’s ability to think beyond the usual limits, and two white stars on the left represent the two missions - Arcanum and Astraeus - which the group is currently working on.
                    
                    Finally, at the bottom of the seal, the motto ‘Through Knowledge To The Stars’ shows the firm basis in absolute knowledge our group seeks for its ambitious proposals and concepts.

                </p>


                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>

                    <strong className='font-semibold'>Personal Reflections</strong><br/>
                    
                    <figure className='bg-shell rounded-lg p-10 mt-5 shadow-xl'>

                        <blockquote>
                            "The central aspect of this seal is the one in which I personally feel the most pride, striving to connect the world and bring people together in pursuit of common goals is something we should all do in every aspect of our lives.
                            I have been very fortunate to welcome many forward-thinking individuals into our team over these past two years, and these values and the bond we have to the group will propagate around the world thanks to their role as a node in the global Conex network.
                            I have had the great privilege of meeting many of our members in person while presenting our work internationally, and I look forward to meeting even more of them over the coming years.
                            It has been inspiring to meet so many talented individuals, and it has strongly reaffirmed my belief that the next generation is more than willing and able to deal with the challenges facing us."
                        </blockquote>

                        <figcaption className='mt-3 text-right'>
                            - <strong>James McKevitt</strong>, <br className='sm:hidden'/>Co-Executive Director
                        </figcaption>

                    </figure>

                    <figure className='bg-shell rounded-lg p-10 mt-10 shadow-xl'>

                        <blockquote>
                            "We created Conex to work on something we are passionate about, do exactly the tasks we are currently interested in and talk about all the creative ideas that come to us.
                            We began with 2 to 3 people joining meetings on a regular basis and are now after 2 years we are at the point where we have a core team of over 20 people and over 60 contributors. 
                            My favourite part about Conex Research is that we recently managed to reach a stage where each of our sub-teams holds asynchronous meetings in addition to our main Friday session.
                            The team is passionate about our work and put lots of time and effort into the missions. It's a great place to learn about new things in a relaxed environment and it is an incredible experience for me to see everyone working together and helping each other.
                            Everyone has their own set of skills and is an essential part of the team, and I really enjoy working with people from different backgrounds."
                        </blockquote>

                        <figcaption className='mt-3 text-right'>
                            - <strong>Christina Bornberg</strong>, <br className='sm:hidden'/>Co-Executive Director
                        </figcaption>

                    </figure>

                </p>


                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                        <img src={img_2_0} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_2_1} className=' rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_2_2} className='rounded-xl'/>
                    </div>
                </div>


                <p className='font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64'>
                    
                    <strong className='font-semibold'>Going Forward</strong><br/><br/>
                    
                    Conex Research will continue to operate with international intent, growing the body of knowledge around our two missions with the hopes of upskilling and inspiring our members.

                    Going forward we plan on advancing the quality of our operations to increase our capacity to enrol new members. We are also seeking to engage and collaborate more with the wider space industry to provide additional greater value to our members through collaborative space science projects.<br/><br/>

                    We look forward to the times ahead and are excited to announce future developments.

                </p>

            </section>

        </Layout>
    )
}
